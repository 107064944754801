import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Settings from "./AddFocusItems/Settings";
import { Error, Success, handleCancel } from "../../../reusableComponents/Alerts";
import Sidebar from "./AddFocusItems/SideBar";
import ThemeCards from "./AddFocusItems/ThemeCards";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Builder from "./AddFocusItems/Builder";
import { AiFillWarning, AiOutlineSave } from "react-icons/ai";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import { FaCube } from "react-icons/fa";
import Header from "../../../reusableComponents/Header/Header";
import Wrapper from "../../../reusableComponents/Wrapper/Wrapper";
import {
  useAddNewFocusItemMutation,
  useGetFocusDataQuery,
  useGetFocusEditorOptionQuery,
  useUpdateFocusItemsMutation,
} from "../../../../redux/api/focusItemsApi";
import SidebarSkeleton from "../../../reusableComponents/SidebarSkeleton";
import Swal from "sweetalert2";
import TitleHeader from "../../../reusableComponents/TitleHeader";
import HeaderSkeleton from "../../../reusableComponents/HeaderSkeleton";
import { t } from "i18next";
import Loading from "../../../reusableComponents/loading";

function AddFocusItems() {
  const navigate = useNavigate();
  const location = useLocation();
  const isClone = location.pathname.includes("clone");
  const id = location?.state?.id || location?.state || useParams().id;

  const [errors, setErrors] = useState({});
  const [formProperties, setFormProperties] = useState([]);
  const [primarycolorTab, setPrimaryColorTab] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [themes, setThemes] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [applyAndCloseTemplate, setApplyAndCloseTemplate] = useState(null);

  const { data, isFetching, isLoading } = useGetFocusDataQuery(id);
  const [addNewFocusItem, { isLoading: isAddLoading }] = useAddNewFocusItemMutation();
  const [updateFocusItems, { isLoading: isEditLoading }] = useUpdateFocusItemsMutation(id);
  const { data: editorData } = useGetFocusEditorOptionQuery();
  const isSaving = isEditLoading || isAddLoading;

  const [modifiedData, setModifiedData] = useState({
    name: "",
    category: null,
    website: null,
    isPublished: true,
    publishUp: null,
    publishDown: null,
    description: null,
    utmTags: {
      utmSource: null,
      utmMedium: null,
      utmCampaign: null,
      utmContent: null,
    },
    type: "",
    id: null,
    properties: {
      animate: null,
      link_activation: null,
      frequency: null,
      when: null,
      timeout: null,
      "margin-top": "",
      "margin-right": "",
      "margin-left": "",
      "margin-bottom": "",
    },
    stop_after_conversion: null,
    json: null,
    customHtml: null,
  });
  console.log("🚀 ~ AddFocusItems ~ modifiedData:", modifiedData);

  const isModifiedDataNotEmpty =
    modifiedData &&
    modifiedData.json &&
    (() => {
      try {
        const customJsonData = JSON.parse(modifiedData.json);
        return customJsonData.body.rows.some((row) =>
          row.columns.some((column) => column.contents && column.contents.length > 0)
        );
      } catch (e) {
        return false;
      }
    })();

  useEffect(() => {
    if (modifiedData && isModifiedDataNotEmpty) {
      setPrimaryColorTab(2);
    }
  }, [modifiedData]);

  useEffect(() => {
    if (data === undefined) return;

    const formProperties = { ...data?.builderPreviewData?.formProperties };
    setFormProperties(formProperties);

    const templates = { ...data?.themesData };
    setThemes(Object.entries(templates).map((x) => x));

    if (id && data?.focusData?.focus) {
      let updatedfocusData = { ...data?.focusData?.focus };
      if (isClone) {
        updatedfocusData = { ...updatedfocusData, isPublished: false };
      }
      setModifiedData({
        ...updatedfocusData,
        category: updatedfocusData?.category
          ? {
              label: updatedfocusData?.category?.title,
              value: updatedfocusData?.category?.id,
            }
          : null,
      });
    }
  }, [id, data]);

  // Function to check if configuration is complete
  const isConfigurationComplete = () => {
    return (
      modifiedData?.name &&
      modifiedData?.type &&
      modifiedData?.properties?.when &&
      !Object.values(errors).some((value) => value === true)
    );
  };

  const handleAddFocus = async (params) => {
    // Validation checks and setting errors
    if (modifiedData?.name.length < 1) {
      errors.name = true;
      setErrors({ ...errors });
    } else {
      delete errors.name;
      setErrors({ ...errors });
    }

    if (modifiedData?.type === "") {
      errors.type = true;
      setErrors({ ...errors });
      setPrimaryColorTab(2);
    } else {
      delete errors.type;
      setErrors({ ...errors });
    }

    if (modifiedData?.properties?.when === "") {
      errors.when = true;
      setErrors({ ...errors });
      setPrimaryColorTab(2);
    } else {
      delete errors.when;
      setErrors({ ...errors });
    }

    const hasErrors = Object.values(errors).some((value) => value === true);

    if (hasErrors) {
      setShowAlert(true);
      setAlertMessage(t("caymland.core.error.not.valid"));
      setTimeout(() => {
        setShowAlert(false);
        setAlertMessage("");
      }, 3500);
    }
    if (Object.keys(errors).length === 0) {
      try {
        let response;
        const payload = {
          ...modifiedData,
          category:
            modifiedData.category && typeof modifiedData.category === "object"
              ? modifiedData.category.value
              : modifiedData.category,
        };
        if (id && !isClone) {
          response = await updateFocusItems({ id, ...payload });
        } else {
          response = await addNewFocusItem(payload);
        }
        if (!response?.error) {
          Success(
            `${t("caymland.webhook.label.success")} ${t("caymland.message.type.successfully", {
              type: id ? "edited" : "created",
            })}`
          ).then((result) => {
            if (result.isConfirmed && params === "savebuilder") {
              navigate(`/focus/view/${response?.data?.focus?.id}`);
            } else {
              navigate(`/focus/edit/${response?.data?.focus?.id}`);
            }
          });
        } else {
          Error(t("caymland.message.error"));
        }
      } catch (e) {
        Error(t("caymland.message.error"));
      }
    }
  };
  const handleSubmit = async (params) => {
    await applyAndCloseTemplate(params);
  };
  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.cancel"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => handleCancel("/focus", navigate),
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose")?.replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: () => handleSubmit("savebuilder"), // Pass the parameter "savebuilder"
      show: false,
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: () => handleSubmit("applyBuilder"), // Pass the parameter "applyBuilder"
      show: false,
    },
  ];

  return (
    <>
      {isLoading ? (
        <HeaderSkeleton />
      ) : (
        <Header
          buttons={buttons}
          showAlert={showAlert}
          alertMessage={alertMessage}
          loading={isSaving}
          title={<TitleHeader name="Focus" clone={isClone} title={modifiedData?.name} loading={isLoading} id={id} />}
        />
      )}

      <Wrapper
        childrenClassOne={"w-75 json-html-style"}
        childrenOne={
          <>
            <Nav className="nav-primary w-full flex" tabs>
              <NavItem>
                <NavLink
                  href="#"
                  className={primarycolorTab === 2 ? "active" : ""}
                  onClick={() => setPrimaryColorTab(2)}
                  style={{
                    color: "#313131",
                    textTransform: "capitalize",
                  }}
                >
                  {"Builder"}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={primarycolorTab === 1 ? "active" : ""}
                  onClick={() => setPrimaryColorTab(1)}
                  style={{
                    color: "#313131",
                    textTransform: "capitalize",
                  }}
                >
                  {t("caymland.core.settings")}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={primarycolorTab} style={{ display: "flex" }}>
              <TabPane tabId={1} style={{ flex: "1", width: "100%", padding: "20px" }}>
                {data && modifiedData && (
                  <Settings
                    modifiedData={modifiedData}
                    setModifiedData={setModifiedData}
                    errors={errors}
                    formProperties={formProperties}
                    setErrors={setErrors}
                    retrieveProperty={data?.builderPreviewData?.formProperties}
                    setFormProperties={setFormProperties}
                  />
                )}
                <div style={{ marginTop: "30px" }}>
                  <ThemeCards themes={themes} setModifiedData={setModifiedData} modifiedData={modifiedData} />
                </div>
              </TabPane>
              <TabPane tabId={2} style={{ flex: "1" }}>
                {editorData ? (
                  <Builder
                    editorData={editorData}
                    setModifiedData={setModifiedData}
                    modifiedData={modifiedData}
                    isOpen={true} // Or remove this prop if not required
                    setIsOpen={setIsOpen} // This can be removed if not needed
                    modalCls=""
                    loading={isFetching}
                    title={
                      <TitleHeader
                        name="Focus"
                        clone={isClone}
                        title={modifiedData?.name}
                        loading={isLoading}
                        id={id}
                      />
                    }
                    apply={handleAddFocus}
                    setApplyAndCloseTemplateRef={setApplyAndCloseTemplate} // Pass the setter as prop
                  />
                ) : (
                  <Loading />
                )}
              </TabPane>
            </TabContent>
          </>
        }
        childrenClassTwo={"wrapper-childTwo"}
        childrenTwo={
          isLoading ? (
            <SidebarSkeleton />
          ) : (
            <Sidebar
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              errors={errors}
              setErrors={setErrors}
            />
          )
        }
      />
    </>
  );
}

export default AddFocusItems;
