import { useCallback, useEffect, useMemo, useState } from "react";
import ReactFlow, { Background, Controls, MiniMap, useEdgesState, useNodesState } from "reactflow";
import "reactflow/dist/style.css";
import { Source, Decision, Condition, Action, Modal } from "./components";
import Add from "./components/nodes/Add";

const Builder = ({ nodesData, forms, actions, preview, interactive = true, isNew }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(nodesData?.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(nodesData?.edges);
  const [params, setParams] = useState({ isOpen: false });
  const [hasJumpNodeActive, setHasJumpNodeActive] = useState(false);
  const [jumpNode, setJumpNode] = useState({});

  useEffect(() => {
    setNodes(nodesData?.nodes);
    setEdges(nodesData?.edges);

    if (!nodesData?.nodes?.length) {
      setNodes([
        {
          id: "add",
          type: "add",
          data: { params: { type: "leadSource", eventType: "leadSource", name: "Source" } },
          position: { x: 700, y: 50 },
        },
      ]);
      setParams({
        isOpen: isNew ? true : false,
        type: "leadSource",
        action: "add",
        parent: { params: {} },
        step: 0,
        actions,
      });
    }
  }, [nodesData]);

  const handleAdd = useCallback((setParams, type, action, step, anchors, actions, data) => {
    if (data) {
      setParams({
        isOpen: true,
        type,
        action,
        step,
        anchors,
        actions,
        parent: { ...data },
      });
    }
  }, []);

  const handleJump = useCallback((data) => {
    setHasJumpNodeActive((prevState) => !prevState);
    setJumpNode({ first: `${data?.params?.id}`, second: `${data?.params?.properties["jumpToEvent"]}` });
  }, []);

  const handleEdit = useCallback((setParams, type, action, step, actions, data) => {
    if (data) {
      setParams({
        isOpen: true,
        type,
        action,
        step,
        actions,
        ...data,
      });
    }
  }, []);

  const onNodeChangePosition = useCallback(
    (changes) => {
      const {
        nodes: { fields, update },
      } = forms;
      const { id, position = false } = changes[0];
      if (
        !hasJumpNodeActive ||
        changes.some((change) => change.id === jumpNode.first || change.id === jumpNode.second)
      ) {
        if (position) {
          let index = fields.findIndex((ff) => ff.id === id);
          if (index !== -1) {
            let node = fields[index];
            update(index, { ...node, ...{ positionX: position.x, positionY: position.y } });
          }
        }
      }
    },
    [forms, hasJumpNodeActive, jumpNode]
  );

  const onConnect = useCallback(
    (params) => {
      const { source, sourceHandle, target } = params;
      const {
        edges: { fields, remove },
      } = forms;

      let index = fields.findIndex((ff) => ff.targetId === target);
      if (index !== -1) {
        remove(index);
      }

      const {
        edges: { append },
      } = forms;
      append({
        sourceId: source,
        targetId: target,
        anchors: {
          source: sourceHandle,
          target: "top",
        },
      });
    },
    [forms]
  );

  const onEdgeUpdate = useCallback(
    (oldEdge, newConnection) => {
      if (!hasJumpNodeActive) {
        onEdgeUpdateEnd(null, oldEdge);
        onConnect(newConnection);
      }
    },
    [forms, hasJumpNodeActive]
  );

  const onEdgeUpdateEnd = useCallback(
    (_, edge) => {
      const { source, target } = edge;
      const {
        edges: { fields, remove },
      } = forms;
      if (!hasJumpNodeActive) {
        let index = fields.findIndex((ff) => ff.sourceId === source && ff.targetId === target);
        if (index !== -1) {
          remove(index);
        }
      }
    },
    [forms, hasJumpNodeActive]
  );

  const nodeTypes = useMemo(
    () => ({
      add: (props) => (
        <Add setParams={setParams} actions={actions} handleAdd={handleAdd} handleEdit={handleEdit} {...props} />
      ),
      source: (props) => (
        <Source
          setParams={setParams}
          actions={actions}
          hasJumpNodeActive={hasJumpNodeActive}
          jumpNode={jumpNode}
          handleAdd={handleAdd}
          handleEdit={handleEdit}
          {...props}
        />
      ),
      decision: (props) => (
        <Decision
          setParams={setParams}
          actions={actions}
          hasJumpNodeActive={hasJumpNodeActive}
          jumpNode={jumpNode}
          handleAdd={handleAdd}
          handleEdit={handleEdit}
          {...props}
        />
      ),
      condition: (props) => (
        <Condition
          setParams={setParams}
          actions={actions}
          hasJumpNodeActive={hasJumpNodeActive}
          jumpNode={jumpNode}
          handleAdd={handleAdd}
          handleEdit={handleEdit}
          {...props}
        />
      ),
      action: (props) => (
        <Action
          setParams={setParams}
          actions={actions}
          handleAdd={handleAdd}
          hasJumpNodeActive={hasJumpNodeActive}
          jumpNode={jumpNode}
          handleJump={handleJump}
          handleEdit={handleEdit}
          {...props}
        />
      ),
    }),
    [nodesData, hasJumpNodeActive]
  );

  return (
    <div
      style={{
        display: "block",
        width: "100%",
        height: "100%",
        background: hasJumpNodeActive ? "rgba(0,0,0,0.3)" : "#fff",
      }}
    >
      {!preview && (
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          onNodesChange={onNodeChangePosition}
          onEdgesChange={onEdgesChange}
          snapToGrid
          onEdgeUpdate={onEdgeUpdate}
          onEdgeUpdateEnd={onEdgeUpdateEnd}
          onConnect={onConnect}
          fitView
          elementsSelectable
          nodesConnectable
          nodesDraggable
          zoomOnDoubleClick
        >
          <Controls />
          <MiniMap nodeColor={"blue"} nodeStrokeWidth={3} zoomable pannable />
          <Background variant="dots" gap={12} size={1} />
        </ReactFlow>
      )}
      {interactive && <Modal props={params} setProps={setParams} nodesData={nodesData} />}

      {preview && (
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          snapToGrid
          onConnect={onConnect}
          fitView
          elementsSelectable={false}
          nodesConnectable={false}
          nodesDraggable={false}
          zoomOnDoubleClick={false}
        >
          <MiniMap nodeColor={"blue"} nodeStrokeWidth={3} zoomable pannable />
          <Background variant="dots" gap={12} size={1} />
        </ReactFlow>
      )}
    </div>
  );
};

export default Builder;
