import React, { useMemo, useState } from "react";
import { useGetReservationsQuery } from "../../../../../redux/api/contactsApi";
import { t } from "i18next";
import CustomTable from "../../CustomTable/CustomTable";
import moment from "moment";

const Reservation = ({ id }) => {
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderBy: "r.lead",
    orderByDir: "DESC",
  });
  const { data } = useGetReservationsQuery(id);
  console.log(data);

  // Function to group and filter reservations by unique ProcessNumber
  const getFirstReservationPerProcessNumber = (reservations) => {
    const seenProcessNumbers = new Set();
    return reservations.filter((reservation) => {
      if (!seenProcessNumbers.has(reservation.ProcessNumber)) {
        seenProcessNumbers.add(reservation.ProcessNumber);
        return true;
      }
      return false;
    });
  };

  const ReservationStateMapping = {
    0: t("caymland.tomas.custom.fields.test"),
    1: t("caymland.tomas.custom.fields.aktiv"),
    2: t("caymland.tomas.custom.fields.abgeschlossen"),
    3: t("caymland.tomas.custom.fields.storniert"),
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "number",
        accessorFn: (row) => row.ProcessNumber,
        header: t("caymland.tomas.table.fields.process_number"),
        enableColumnActions: false,
        size: 50,
      },
      {
        accessorKey: "ReservationType",
        accessorFn: (row) => row.ReservationType,
        header: t("caymland.reservation.table.info.ReservationType"),
        enableColumnActions: false,
        size: 50,
      },
      {
        accessorKey: "ReservationInternalType",
        accessorFn: (row) => {
          switch (row?.ReservationInternalType) {
            case 0:
              return t("caymland.tomas.custom.fields.buchung");
            case 1:
              return t("caymland.tomas.custom.fields.option");
            case 3:
              return t("caymland.tomas.custom.fields.angebot");
            case 5:
              return t("caymland.tomas.custom.fields.prospektanfrage");
            case 7:
              return t("caymland.tomas.custom.fields.eigenbelegung");
            default:
              return row?.ReservationInternalType || ""; // Return the original value or an empty string if undefined
          }
        },
        header: t("caymland.tomas.table.fields.internal_type"),
        enableColumnActions: false,
        size: 50,
      },
      {
        accessorKey: "ReservationState",
        accessorFn: (row) => ReservationStateMapping[row?.ReservationState] || row?.ReservationState,
        header: t("caymland.tomas.table.fields.reservation_state"),
        enableColumnActions: false,
        size: 50,
      },

      {
        accessorKey: "ServiceProviderName",
        accessorFn: (row) => `${row.ServiceProviderName}`,
        header: t("caymland.tomas.table.fields.service_provider_name"),
        enableColumnActions: false,
        size: 100,
        Cell: ({ row }) => {
          console.log(row);
          const displayText =
            row.original.ServiceProviderName?.length > 15
              ? `${row.original.ServiceProviderName?.substring(0, 15)}...`
              : row.original.ServiceProviderName;
          return (
            <span style={{ cursor: "help" }} title={row.original.ServiceProviderName}>
              {displayText}
            </span>
          );
        },
      },
      {
        accessorKey: "ServiceProviderType",
        accessorFn: (row) => `${row.ServiceProviderType}`,
        header: t("caymland.tomas.table.fields.service_provider_type"),
        enableColumnActions: false,
        size: 100,
        Cell: ({ row }) => {
          console.log(row);
          const displayText =
            row.original.ServiceProviderType?.length > 15
              ? `${row.original.ServiceProviderType?.substring(0, 15)}...`
              : row.original.ServiceProviderType;
          return (
            <span style={{ cursor: "help" }} title={row.original.ServiceProviderType}>
              {displayText}
            </span>
          );
        },
      },
      {
        accessorKey: "SalesChannelName",
        accessorFn: (row) => `${row.SalesChannelName}`,
        header: t("caymland.tomas.table.fields.sales_channel_name"),
        enableColumnActions: false,
        size: 100,
        Cell: ({ row }) => {
          console.log(row);
          const displayText =
            row.original.SalesChannelName?.length > 15
              ? `${row.original.SalesChannelName?.substring(0, 15)}...`
              : row.original.SalesChannelName;
          return (
            <span style={{ cursor: "help" }} title={row.original.SalesChannelName}>
              {displayText}
            </span>
          );
        },
      },
      {
        accessorKey: "Persons",
        accessorFn: (row) => `${row.Persons}`,
        header: t("caymland.reservation.table.info.Persons"),
        enableColumnActions: false,
        size: 50,
      },
      {
        accessorKey: "FromDate",
        accessorFn: (row) => moment(row?.FromDate).format("DD.MM.YY"),
        header: t("caymland.reservation.table.info.FromDate"),
        enableColumnActions: false,
        size: 100,
      },
      {
        accessorKey: "ToDate",
        accessorFn: (row) => moment(row?.ToDate).format("DD.MM.YY"),
        header: t("caymland.reservation.table.info.ToDate"),
        enableColumnActions: false,
        size: 100,
      },
      {
        accessorKey: "Price",
        accessorFn: (row) => `${row.Persons}`,
        header: t("caymland.tomas.table.fields.price"),
        enableColumnActions: false,
        size: 100,
        Cell: ({ cell }) => (
          <span>
            {cell.row.original.CurrencyCode} {cell.row.original.Price}
          </span>
        ),
      },
    ],
    []
  );

  // Filter the reservations to show only the first one per unique ProcessNumber
  const firstReservations = data?.reservations ? getFirstReservationPerProcessNumber(data.reservations) : [];

  return (
    <CustomTable
      data={firstReservations} // Pass filtered data
      columns={columns}
      reservationData={data}
      query={query}
      setQuery={setQuery}
      // footer
      mediumHeight
      // header
      actions={false}
      linkNavigate={null}
      showDetailPanel
      reservations
    />
  );
};

export default Reservation;
