import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import { SlideAlert, Success, handleCancelBuilder } from "../../reusableComponents/Alerts";
import Details from "./Details";
import Builder from "./Builder";
import schema from "./components/utils/Schema";
import { useFieldArray, useForm } from "react-hook-form";
import {} from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import ListIcon from "@mui/icons-material/List";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CommitIcon from "@mui/icons-material/Commit";
import { Styles } from "./components/utils/index";
import { transformFields, unsetUnnecessaryField } from "../../helpers/transformFields";
import { FaCheckDouble, FaCube, FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import { Event } from "./components/utils/Event";
import { Edges } from "./components/utils/Edges";
import { Nodes } from "./components/utils/Nodes";
import Header from "../../reusableComponents/Header/Header";
import Wrapper from "../../reusableComponents/Wrapper/Wrapper";
import {
  useAddNewCampaignMutation,
  useGetCampaignQuery,
  useGetEventTypesQuery,
  useUpdateCampaignsMutation,
} from "../../../redux/api/campaignsApi";
import SidebarSkeleton from "../../reusableComponents/SidebarSkeleton";
import DetailsSkeleton from "../../reusableComponents/DetailsSkeleton";
import EdgeLabel from "./components/utils/EdgeLabel";
import HeaderSkeleton from "../../reusableComponents/HeaderSkeleton";
import TitleHeader from "../../reusableComponents/TitleHeader";
import { t } from "i18next";
import Swal from "sweetalert2";

function AddCampaign() {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.id || location?.state || useParams().id;
  const isClone = location.pathname.includes("clone");
  const [isOpen, setIsOpen] = useState(false);
  const { data: getEventTypes, error } = useGetEventTypesQuery();
  const { data: _getCampaign, isFetching, isLoading: loadingCampang } = useGetCampaignQuery(id, { skip: !id });
  const [updateCampaign, { isLoading: isEditLoading }] = useUpdateCampaignsMutation(id);
  const [addNewCampaign, { isLoading: isAddLoading }] = useAddNewCampaignMutation();
  const isSaving = isEditLoading || isAddLoading;

  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const {
    getValues,
    setValue,
    reset,
    watch,
    control,
    handleSubmit,
    register,
    formState: { errors, isLoading },
  } = useForm({
    defaultValues: {
      events: [],
      lists: [],
      forms: [],
      isPublished: false,
      canvasSettings: { nodes: [], connections: [] },
      description: "",
    },
    resolver: yupResolver(schema),
  });
  const {
    fields: listsFields,
    append: listsAppend,
    remove: listsRemove,
    update: listsUpdate,
    replace: listsReplace,
  } = useFieldArray({
    keyName: "formId",
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "lists", // unique name for your Field Array
  });

  const {
    fields: eventsFields,
    append: eventsAppend,
    remove: eventsRemove,
    update: eventsUpdate,
    replace: eventsReplace,
  } = useFieldArray({
    keyName: "formId",
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "events", // unique name for your Field Array
  });

  const {
    fields: formsFields,
    append: formsAppend,
    remove: formsRemove,
    update: formsUpdate,
    replace: formsReplace,
  } = useFieldArray({
    keyName: "formId",
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "forms", // unique name for your Field Array
  });

  const {
    fields: nodesFields,
    append: nodesAppend,
    remove: nodesRemove,
    update: nodesUpdate,
    replace: nodesReplace,
  } = useFieldArray({
    keyName: "formId",
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "canvasSettings.nodes", // unique name for your Field Array
  });

  const {
    fields: connectionsFields,
    append: connectionsAppend,
    remove: connectionsRemove,
    update: connectionsUpdate,
    replace: connectionsReplace,
  } = useFieldArray({
    keyName: "formId",
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "canvasSettings.connections", // unique name for your Field Array
  });

  const leadSourceList = useMemo(
    () => [
      {
        id: 1,
        label: t("caymland.campaign.leadsource.lists"),
        alias: "lists",
        background: "#04B76B",
        icon: <ListIcon />,
        used: false,
      },
      {
        id: 2,
        label: t("caymland.form.type.campaign"),
        alias: "forms",
        background: "#F5A623",
        icon: <ReceiptIcon />,
        used: false,
      },
    ],
    []
  );

  const actionSourceList = useMemo(
    () => [
      {
        id: 1,
        label: t("caymland.campaign.event.decision.header"),
        alias: "decision",
        background: "#04B76B",
        icon: <ShuffleIcon />,
        connectionRestrictions: {
          source: {
            leadSource: ["lists", "forms"],
            condition: ["condition"],
            action: ["action"],
            decision: ["none"],
          },
        },
      },
      {
        id: 2,
        label: t("caymland.campaign.event.condition.header"),
        alias: "condition",
        background: "#F5A623",
        icon: <FilterAltIcon />,
      },
      {
        id: 3,
        label: t("caymland.dncevent.campaign.addCat.action"),
        alias: "action",
        background: "#0B94F7",
        icon: <CommitIcon />,
      },
    ],
    []
  );

  const handleDeleteNode = useCallback(
    (params) => {
      // let index = eventsFields.findIndex((ef => ef.id == params.id));
      // if (index !== -1) {
      //     eventsRemove(index);
      // }

      // let nodesFieldsIndex = nodesFields.map((nf, i) => {
      //     if (nf.id == params.id) {
      //         return i;
      //     }
      // }).filter(Boolean);

      // if (nodesFieldsIndex.length) {
      //     nodesRemove(nodesFieldsIndex);
      // }

      let index = eventsFields.findIndex((ef) => ef.id == params.id);
      if (index !== -1) {
        eventsRemove(index);
      }

      const nodeFieldIndex = nodesFields.findIndex((ef) => ef.id == params.id);
      if (nodeFieldIndex !== -1) {
        nodesRemove(nodeFieldIndex);
      }

      // const connectionFieldIndex = connectionsFields.findIndex((ef => ef.targetId == params.id || ef.sourceId == params.id));
      // if (connectionFieldIndex !== -1) {
      //     connectionsRemove(connectionFieldIndex);
      // }

      const connectionsFieldsIndex = connectionsFields
        .map((cf, i) => {
          if (cf.targetId == params.id || cf.sourceId == params.id) {
            return i;
          }
        })
        .filter(Boolean);

      if (connectionsFieldsIndex.length) {
        connectionsRemove(connectionsFieldsIndex);
      }

      if (params.id === "lists") listsRemove();

      if (params.id === "forms") formsRemove();
    },
    [connectionsFields, nodesFields]
  );

  const {
    watch: eventWatch,
    getValues: eventGetValues,
    setValue: eventSetValue,
    control: eventFormControl,
    reset: eventFormReset,
    handleSubmit: eventFormHandleSubmit,
    formState,
  } = useForm();

  const [events, setEvents] = useState([]);
  const getEventsTypes = () =>
    new Promise(async (resolve, reject) => {
      resolve(getEventTypes);
    });

  useEffect(() => {
    // if (_.isEmpty(events)) {
    getEventsTypes()
      .then((response) => {
        setEvents({
          source: leadSourceList,
          actions: actionSourceList,
          events: response,
          fields: [],
        });
      })
      .catch((e) => []);
    // }
  }, [leadSourceList, actionSourceList, getEventTypes]);

  const getEventsBySource = useCallback(
    async ({ source, step, type }) =>
      new Promise(async (resolve, reject) => {
        if (source === "leadSource") {
          switch (step) {
            case 0:
              resolve(leadSourceList);
              break;
          }
          return;
        }

        switch (step) {
          case 0:
            resolve(events["actions"]);
            break;
          case 1:
            resolve(events?.events[source]);
            break;
        }
      }),
    [events]
  );

  const generateUniqueId = () => {
    return "new" + Date.now().toString() + Math.floor(Math.random() * 1000000);
  };

  const [cloneEventFields, setCloneEventFields] = useState([]);
  const [idMapping, setIdMapping] = useState({});
  const [flag, setFlag] = useState(true);

  const cloneFuncIds = async () => {
    const newIdMapping = {};
    const newEventsFields = eventsFields.map((event) => {
      const newId = generateUniqueId();
      newIdMapping[event.id] = newId;
      return {
        ...event,
        id: newId,
      };
    });

    setCloneEventFields(newEventsFields);
    setIdMapping(newIdMapping);
    if (eventsFields?.length > 0) setFlag(false);
  };

  useEffect(() => {
    if (isClone && flag === true) {
      cloneFuncIds();
    }
  }, [eventsFields]);

  useEffect(() => {
    if (cloneEventFields?.length > 0) {
      eventsReplace(cloneEventFields);
    }
  }, [cloneEventFields]);

  const updateNodeFields = useCallback(() => {
    nodesFields.map((node, index) => {
      const newId = idMapping[node.id];
      if (newId) {
        nodesUpdate(index, { ...node, id: newId });
      }
    });
  }, [idMapping, nodesFields]);

  const updateConnectionFields = useCallback(() => {
    connectionsFields.map((connection, index) => {
      const newSourceId = idMapping[connection.sourceId];
      const newTargetId = idMapping[connection.targetId];
      let updatedConnection = { ...connection };
      if (newSourceId) {
        updatedConnection.sourceId = newSourceId;
      }
      if (newTargetId) {
        updatedConnection.targetId = newTargetId;
      }
      connectionsUpdate(index, updatedConnection);
    });
  }, [idMapping, connectionsFields]);

  useEffect(() => {
    if (Object.keys(idMapping).length > 0) {
      updateNodeFields();
      updateConnectionFields();
    }
  }, [idMapping, updateNodeFields]);

  function transformFilters(originalFilters) {
    const condition = originalFilters?.combinator ? originalFilters.combinator.toUpperCase() : "AND";

    const transformedFilters = {
      condition: condition,
      rules: originalFilters?.rules?.map((rule) => ({
        id: rule?.field?.id,
        field: rule?.field?.field,
        type: rule?.field?.type,
        input: rule?.field?.input === "integer" || rule?.field?.input === "double" ? "number" : rule?.field?.input,
        object: rule?.field?.object,
        operator: rule?.operator,
        value: rule?.value,
      })),
      valid: true,
    };
    return JSON.stringify(transformedFilters);
  }

  function transformFiltersEdit(originalFilters) {
    const condition = originalFilters?.combinator ? originalFilters.combinator.toUpperCase() : "AND";
    const transformedFilters = {
      condition: condition,
      rules: originalFilters?.rules?.map((rule) => ({
        id: rule?.id,
        field: rule?.field,
        type: rule?.type,
        input: rule?.input === "integer" || rule?.input === "double" ? "number" : rule?.input,
        object: rule?.object || "Showare",
        operator: rule?.operator,
        value: rule?.value,
      })),
      valid: true,
    };
    return JSON.stringify(transformedFilters);
  }

  const addOrEditEvent = useCallback(
    (_data) => {
      let data = _data;
      console.log(data);
      let eventType = _data?.eventType;
      let type = _data?.type;
      if (
        eventType === "form.field_value" ||
        type === "form.field_value" ||
        eventType === "showare.condition" ||
        type === "showare.condition" ||
        _data?.triggerMode === "date"
      ) {
        const existingEventIndex = eventsFields.findIndex((ef) => ef.id === data.id);
        if (eventType === "showare.condition" || type === "showare.condition") {
          if (data?.properties?.filters && existingEventIndex === -1) {
            let originalFilters =
              typeof data.properties.filters === "string"
                ? JSON.parse(data.properties.filters)
                : data.properties.filters;
            const transformedFilters = transformFilters(originalFilters);
            data.properties.filters = transformedFilters;
          } else {
            const transformedFilters = transformFiltersEdit(data.properties.filters);
            data.properties.filters = transformedFilters;
          }
        } else {
          data = unsetUnnecessaryField(transformFields({ ..._data }));
        }
      }
      if (eventType === "lead.field_value" || type === "lead.field_value") {
        if (data.properties.operator === "!empty" || data.properties.operator === "empty") {
          data.properties.value = null;
        }
      }
      const existingEventIndex = eventsFields.findIndex((ef) => ef.id === data.id);

      if (existingEventIndex !== -1) {
        eventsUpdate(
          existingEventIndex,
          Event({
            id: data?.id,
            type: data?.type,
            eventType: data?.eventType,
            name: data?.name,
            anchors: data?.anchors,
            properties:
              data.eventType == "dncevent.addDnc" ? data : data.type == "dncevent.removeDnc" ? data : data.properties,
            triggerMode:
              data?._formValues?.eventType === "lead.scorecontactscompanies" && data?._formValues?.anchors === "no"
                ? "interval"
                : data?.triggerMode,
            triggerRestrictedDaysOfWeek: data?.triggerRestrictedDaysOfWeek,
            triggerHour: data?.triggerHour,
            triggerInterval: data?.triggerInterval,
            triggerIntervalUnit: data?.triggerIntervalUnit,
            triggerRestrictedDaysOfWeek: data?.triggerRestrictedDaysOfWeek,
            triggerRestrictedStartHour: data?.triggerRestrictedStartHour,
            triggerRestrictedStopHour: data?.triggerRestrictedStopHour,
            triggerDate: data?.triggerDate,
            parent: data?.parent,
          })
        );
      } else {
        let parentNode = nodesFields?.find((nf) => nf?.id == data?.parent?.id);

        let positionX = parseInt(parentNode?.positionX) || 0;
        let positionY = parseInt(parentNode?.positionY) || 0;
        switch (data?.anchors) {
          case "leadsourceleft":
            positionX -= 500;
            break;
          case "leadsourceright":
            positionX += 500;
            break;
          case "yes":
            positionX -= 120;
            positionY += 120;
            break;
          case "no":
            positionX += 120;
            positionY += 120;
            break;
          case "leadsource":
          case "bottom":
            positionY += 120;
            break;
        }

        const id = generateUniqueId();
        eventsAppend(
          Event({
            id,
            type: data?.eventType,
            eventType: data?.type,
            name: data?.name,
            anchors: data?.anchors,
            properties:
              data.eventType == "dncevent.addDnc" ? data : data.type == "dncevent.removeDnc" ? data : data.properties,
            triggerMode:
              data?._formValues?.eventType === "lead.scorecontactscompanies" && data?._formValues?.anchors === "no"
                ? "interval"
                : data?.triggerMode,
            triggerRestrictedDaysOfWeek: data?.triggerRestrictedDaysOfWeek,
            triggerHour: data?.triggerHour,
            triggerInterval: data?.triggerInterval,
            triggerIntervalUnit: data?.triggerIntervalUnit,
            triggerRestrictedDaysOfWeek: data?.triggerRestrictedDaysOfWeek,
            triggerRestrictedStartHour: data?.triggerRestrictedStartHour,
            triggerRestrictedStopHour: data?.triggerRestrictedStopHour,
            triggerDate: data?.triggerDate,
            parent: data?.parent,
          })
        );
        nodesAppend(Nodes({ positionY, positionX, id }));
        connectionsAppend(
          Edges({ sourceId: `${data?.parent?.id}`, targetId: id, source: data?.anchors, target: "top" })
        );
      }
    },
    [formState, listsFields, formsFields, eventsFields, nodesFields, connectionsFields]
  );

  const handleNodeAdd = useCallback(
    (data) => {
      if (_.has(data, "lists")) {
        listsReplace(data.lists);
        nodesAppend({ id: "lists", positionX: 550, positionY: 50 });
      } else if (_.has(data, "forms")) {
        formsReplace(data.forms);
        nodesAppend({ id: "forms", positionX: 850, positionY: 50 });
      } else {
        addOrEditEvent(data);
      }
    },
    [nodesFields, formsFields, eventsFields, listsFields]
  );

  function useNodeConnection(logicFunction, listsFields, formsFields) {
    useEffect(() => {
      logicFunction(listsFields, formsFields);
    }, [listsFields, formsFields]);
  }

  const connectSources = (lists, forms) => {
    if (lists?.length > 0 && forms?.length > 0) {
      connectionsAppend(Edges({ sourceId: "lists", targetId: "forms", source: "leadsourceright", target: "top" }));
    }
  };

  useNodeConnection(connectSources, listsFields, formsFields);

  const forms = useMemo(
    () => ({
      events: {},
      nodes: {
        fields: nodesFields,
        update: nodesUpdate,
      },
      edges: {
        fields: connectionsFields,
        append: connectionsAppend,
        remove: connectionsRemove,
        update: connectionsUpdate,
      },
    }),
    [connectionsFields]
  );

  const actions = useMemo(
    () => ({
      deleteNode: (props) => handleDeleteNode(props),
      handleNodeAdd: (props) => handleNodeAdd(props),
      getEventsBySource: (props) => getEventsBySource(props),
    }),
    [formState, listsFields, formsFields, eventsFields, nodesFields, connectionsFields]
  );

  const [nodes, setNodes] = useState({});
  useEffect(() => {
    const initialNodes = {
      nodes: [],
      edges: [],
    };

    let listNode = nodesFields?.find((nf) => nf.id === "lists");
    let formNode = nodesFields?.find((nf) => nf.id === "forms");
    const listsList = listsFields
      .map((lf) => {
        return { name: lf?.name, id: lf?.id };
      })
      .filter(Boolean);
    const formsList = formsFields
      .map((lf) => {
        return { name: lf?.name, id: lf?.id };
      })
      .filter(Boolean);
    if (listNode) {
      initialNodes.nodes.push({
        id: "lists",
        type: "source",
        data: { params: { id: "lists", type: "lists", eventType: "leadSource", name: "lists", lists: listsList } },
        position: { x: parseInt(listNode?.positionX), y: parseInt(listNode?.positionY) },
      });
    }

    if (formNode) {
      initialNodes.nodes.push({
        id: "forms",
        type: "source",
        data: { params: { id: "forms", type: "forms", eventType: "leadSource", name: "forms", forms: formsList } },
        position: { x: parseInt(formNode?.positionX), y: parseInt(formNode?.positionY) },
      });
    }

    if (eventsFields.length) {
      eventsFields.map((ef) => {
        let eventNode = nodesFields.find((nf) => nf.id == ef.id);
        if (!_.isEmpty(eventNode)) {
          const { positionX = false, positionY = false } = eventNode;
          const { id, eventType } = ef;

          initialNodes.nodes.push({
            id: id.toString(),
            type: eventType,
            data: { params: ef },
            position: { x: parseInt(positionX), y: parseInt(positionY) },
          });
        }
      });
    }

    if (connectionsFields.length) {
      initialNodes.edges = connectionsFields.map((cf) => {
        const {
          sourceId,
          targetId,
          anchors: { source, target },
        } = cf;

        const correspondingEvent = eventsFields.find((event) => event.id == targetId);
        let label = "";
        if (correspondingEvent) {
          label = EdgeLabel(correspondingEvent);
        }

        return {
          id: `${_.uniqueId("new")}`,
          source: sourceId,
          target: targetId,
          sourceHandle: source,
          animated: false,
          type: "step",
          style: { stroke: Styles.edgesColor[source] },
          label: label,
        };
      });
    }
    setNodes(initialNodes);
  }, [listsFields, formsFields, eventsFields, nodesFields, connectionsFields]);

  const [loading, setLoading] = useState(false);
  const getCampaign = () =>
    new Promise(async (resolve, reject) => {
      setLoading(true);
      if (_getCampaign?.campaign) {
        let campaignData = _getCampaign?.campaign;
        setLoading(false);
        if (isClone) {
          campaignData = { ...campaignData, isPublished: false };
        }
        resolve(campaignData);
      } else {
        reject(_getCampaign?.error?.message);
      }
    });

  useEffect(() => {
    if (id && _getCampaign) {
      getCampaign()
        .then((response) => {
          reset({
            ...response,
            category: response?.category
              ? {
                  label: response?.category?.title,
                  value: response?.category?.id,
                }
              : null,
          });
        })
        .catch((errors) => console.error(errors));
    }
  }, [id, _getCampaign]);

  useEffect(() => {
    if (!_.isEmpty(errors)) {
      console.error(errors);
      if (errors.name) {
        setShowAlert(true);
        setAlertMessage(t("caymland.core.error.not.valid"));
        setTimeout(() => {
          setAlertMessage("");
          setShowAlert(false);
        }, 3000);
      } else {
        setShowAlert(true);
        setAlertMessage(`${t("caymland.core.required")}! ${t("caymland.core.builder")}`);
        setTimeout(() => {
          setShowAlert(false);
          setAlertMessage("");
        }, 3500);
      }
    }
  }, [errors]);

  const handleOnSubmit = async (data, close = false) => {
    const requestData = await unsetUnnecessaryField(transformFields({ ...data }));
    if (isClone) {
      _.unset(requestData, "id");
    }
    // _.unset(requestData, 'events');
    // _.unset(requestData, 'canvasSettings');

    const response = _.has(requestData, "id") ? await updateCampaign(requestData) : await addNewCampaign(requestData);

    if (!response?.error) {
      Success(
        `${t("caymland.message.queue.report.success")}, ${t("caymland.message.type.successfully", {
          type: _.has(requestData, "id") ? t("caymland.core.type.edited") : t("caymland.dashboard.create.past.tense"),
        })}!`
      ).then((result) => {
        if (result.isConfirmed) {
          navigate(`/campaigns/${close ? "view" : "edit"}/${response.data.campaign.id}`);
        }
      });
      reset(response.data.campaign);
    }
  };

  const buttons = [
    // {
    //   name: "builder",
    //   title: t("caymland.core.builder"),
    //   icon: <FaCube size={15} />,
    //   onClick: () => setIsOpen(watch("name") && true),
    //   show: false,
    //   not_active: watch("name") ? false : true,
    // },
    {
      name: "close",
      title: t("caymland.core.close"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => navigate("/campaigns"),
      show: false,
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose").replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: handleSubmit((data) => handleOnSubmit(data, true)),
      show: false,
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: handleSubmit((data) => handleOnSubmit(data)),
      show: false,
    },
  ];

  const bulderButtons = [
    {
      name: "closeBuilder",
      title: t("caymland.core.close.builder"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => handleCancelBuilder(setIsOpen),
      show: true,
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: handleSubmit((data) => handleOnSubmit(data)),
      show: true,
    },

    {
      name: "save",
      title: t("caymland.core.form.saveandclose")?.replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: handleSubmit((data) => handleOnSubmit(data, true)),
      show: false,
    },
  ];

  return (
    <>
      <SlideAlert show={showAlert} message={alertMessage} setShowAlert={setShowAlert} />
      {loadingCampang ? (
        <HeaderSkeleton hasBuilderButton={true} />
      ) : (
        <Header
          buttons={isOpen ? bulderButtons : buttons}
          loading={isSaving}
          title={
            <TitleHeader
              name={t("caymland.campaign.campaign")}
              clone={isClone}
              title={watch("name")}
              loading={loadingCampang}
              id={id}
            />
          }
        />
      )}

      {isOpen ? (
        <Builder nodesData={nodes} forms={forms} actions={actions} eventFormReset={eventFormReset} />
      ) : (
        <>
          <Wrapper
            childrenClassOne={"wrapper-childOne"}
            childrenOne={
              loadingCampang ? (
                <DetailsSkeleton />
              ) : (
                <Details nodes={nodes} forms={forms} actions={actions} eventFormReset={eventFormReset} />
              )
            }
            childrenClassTwo={"wrapper-childTwo"}
            childrenTwo={
              loadingCampang ? (
                <SidebarSkeleton />
              ) : (
                <Sidebar control={control} watch={watch} setValue={setValue} errors={errors} />
              )
            }
          />
        </>
      )}
    </>
  );
}

export default AddCampaign;
