import { Link, useLocation } from "react-router-dom";
import PublishStatus from "../PublishStatusIcon/PublishStatus";
import { SiGoogletranslate } from "react-icons/si";
import { FaCog } from "react-icons/fa";
import { TbBan } from "react-icons/tb";
import { SiMinutemailer } from "react-icons/si";
import React, { useEffect, useState } from "react";
import { IconButton, Tooltip, Zoom } from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import { t } from "i18next";
import he from "he";

export const LinkComponent = ({
  data,
  loading,
  handleTogglePublished,
  link,
  view,
  modal,
  disableLink = false,
  IpShow,
}) => {
  let MyName = data?.fields?.all?.firstname || "";
  let MyLastName = data?.fields?.all?.lastname || "";
  let MyIpAddress = data?.ipAddresses?.[0]?.ip || "";
  const LinkName = IpShow == "is:anonymous" ? MyIpAddress : `${MyName} ${MyLastName}`;

  const content = (
    <>
      {link === "contacts/fields"
        ? data?.label
        : link === "users"
        ? `${data.lastName}, ${data.firstName}`
        : link === "pages" || link === "assets"
        ? `${he.decode(data?.title)} (${data?.alias})`
        : link === "contacts"
        ? LinkName
        : link === "companies"
        ? data?.fields?.core?.companyname?.value
        : data?.name || data?.title || data?.tag || data.originalFile}
    </>
  );
  const description = (
    <div className="text-muted">
      <p
        style={{
          color: "#989898",
          fontSize: "11px",
          maxWidth: "200px",
        }}
      >
        {/* {data?.description} */}
        <span
          style={{ fontSize: 10 }}
          dangerouslySetInnerHTML={{ __html: data?.description || data?.content || data?.metaDescription }}
        ></span>
      </p>
    </div>
  );

  return (
    <div className={`flex flex-col p-no-margin`}>
      <div className="flex items-center justify-start gap-10">
        {handleTogglePublished && (
          <PublishStatus
            data={data}
            // loading={localLoading}
            handleTogglePublished={handleTogglePublished}
            index={1}
          />
        )}

        {!disableLink ? (
          <Link className="table-links" to={`/${link}/${view ? "view" : "edit"}/${data?.id}`}>
            <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>{content}</div>
            {description && description}
          </Link>
        ) : (
          <Link
            className="table-links"
            onClick={(e) => {
              if (modal && link !== "categories") {
                e.preventDefault();
                e.stopPropagation();
                modal({ isOpen: true, type: "edit", id: data.id });
              }
              if (link === "categories") {
                modal("Edit", data?.id);
              }
            }}
          >
            {content}
            {description && description}
          </Link>
        )}
        {link === "segments" && data.isGlobal === true ? <PublicIcon style={{ fontSize: "14px" }} /> : null}
        {data?.iconClass && (
          <div style={{ marginLeft: "5px" }}>
            <i className={data?.iconClass}></i>
          </div>
        )}
        {data?.isPreferenceCenter && (
          <Tooltip
            arrow
            followCursor
            placement="top"
            TransitionComponent={Zoom}
            title={t("caymland.core.icon_tooltip.preference_center")}
            style={{ padding: "0" }}
          >
            <div className="d-flex">
              <FaCog size={14} />
            </div>
          </Tooltip>
        )}
        {data?.translationChildren?.length ? (
          <Tooltip
            arrow
            followCursor
            placement="top"
            TransitionComponent={Zoom}
            title={t("caymland.core.icon_tooltip.translation")}
            style={{ padding: "0" }}
          >
            <div className="d-flex">
              <SiGoogletranslate size={14} />
            </div>
          </Tooltip>
        ) : (
          ""
        )}
        {data?.emailType === "list" ? (
          <Tooltip
            arrow
            followCursor
            placement="top"
            TransitionComponent={Zoom}
            title={t("caymland.email.icon_tooltip.list_email")}
            style={{ padding: "0" }}
          >
            <div>
              <i class="fa fa-fw fa-pie-chart"> </i>
            </div>
          </Tooltip>
        ) : (
          ""
        )}
        {data?.fields?.all?.doi == 1 ? (
          <div style={{ marginLeft: "5px" }}>
            <Tooltip
              arrow
              followCursor
              placement="top"
              TransitionComponent={Zoom}
              title={t("caymland.lead.timeline.lead.doi.confirm")}
              style={{ padding: "0" }}
            >
              <IconButton>
                <div
                  className="grid place-center"
                  style={{
                    background: "#04B76B",
                    borderRadius: "50%",
                    padding: "3px",
                    width: "100%",
                  }}
                >
                  <SiMinutemailer color="#fff" size={15} />
                </div>
              </IconButton>
            </Tooltip>
          </div>
        ) : data?.doNotContact?.length > 0 && data?.doNotContact[0]?.reason === 2 ? (
          <div style={{ marginLeft: "5px" }}>
            <Tooltip
              arrow
              followCursor
              placement="top"
              TransitionComponent={Zoom}
              title={t("caymland.email.report.bounced")}
              style={{ padding: "0" }}
            >
              <IconButton>
                <div
                  className="grid place-center"
                  style={{
                    background: "#fdb933",
                    borderRadius: "50%",
                    padding: "3px",
                    width: "100%",
                  }}
                >
                  <TbBan color="#fff" size={15} />
                </div>
              </IconButton>
            </Tooltip>
          </div>
        ) : data?.doNotContact?.length > 0 && data?.doNotContact[0]?.reason === 4 ? (
          <div style={{ marginLeft: "5px" }}>
            <Tooltip
              arrow
              followCursor
              placement="top"
              TransitionComponent={Zoom}
              title={t("caymland.lead.list.filter.dnc_soft")}
              style={{ padding: "0" }}
            >
              <IconButton>
                <div
                  className="grid place-center"
                  style={{
                    background: "#fdb933",
                    borderRadius: "50%",
                    padding: "3px",
                    width: "100%",
                  }}
                >
                  <TbBan color="#fff" size={15} />
                </div>
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          data?.doNotContact?.length > 0 && (
            <div style={{ marginLeft: "5px" }}>
              <Tooltip
                arrow
                followCursor
                placement="top"
                TransitionComponent={Zoom}
                title={t("caymland.email.report.unsubscribed")}
                style={{ padding: "0" }}
              >
                <IconButton>
                  <div
                    className="grid place-center"
                    style={{
                      background: "#FF5E6D",
                      borderRadius: "50%",
                      padding: "3px",
                      width: "100%",
                    }}
                  >
                    <TbBan color="#fff" size={15} />
                  </div>
                </IconButton>
              </Tooltip>
            </div>
          )
        )}
      </div>
    </div>
  );
};
