import React, { Fragment, useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/sidebar_logo.png";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuthLoginMutation } from "../../redux/api/authApi";
import { Text, Select } from "../reusableComponents/Inputs";
import LastUpdate from "./last_update.json";
import Moment from "moment";
import "moment/locale/de";
import { useTranslation } from "react-i18next";
import { SlideAlert } from "../reusableComponents/Alerts";
import { FaEye, FaEyeSlash } from "react-icons/fa";

Moment.locale("de");
function Login() {
  const { t } = useTranslation();
  const resolver = useMemo(
    () =>
      yup.object().shape({
        _username: yup.string().required(t("Username is required")),
        _password: yup.string().required(t("Password is required")),
      }),
    [t]
  );

  const [authLogin, { isLoading }] = useAuthLoginMutation();
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [backgorundToast, setBackgroundToast] = useState("");

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      _username: null,
      _password: null,
    },
    resolver: yupResolver(resolver),
  });

  const login = async (credentials) => {
    const response = await authLogin(credentials);
    if (response.error) {
      setShow(true);
      setMessage(t("Invalid login. Please verify credentials."));
      setBackgroundToast("2");
      setTimeout(() => {
        setShow(false);
        setMessage("");
        setBackgroundToast("1");
      }, 5000);
    } else {
      navigate("/");
    }
  };

  const [instance, setInstance] = useState({ label: "dev.m-4.ch", value: "dev.m-4.ch" });
  const onChangeInstance = (data) => {
    setInstance(data);
  };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Fragment>
      <SlideAlert background={backgorundToast} show={show} message={message} setShowAlert={setShow} />
      <div className="page-wrapper">
        <div className="auth-bg">
          <div className="authentication-box">
            <div className="text-center">
              <img src={logo} alt="company logo" />
            </div>
            <div className="card mt-4">
              <div className="card-body">
                <form onSubmit={handleSubmit(login)}>
                  <div className="text-center">
                    <h4>{t("Login")}</h4>
                  </div>
                  <div className="input-container ic1">
                    <Controller
                      control={control}
                      name={"_username"}
                      render={({ field: { name, onChange, value } }) => (
                        <Text name={name} value={value} onChange={onChange} label={t("Username")} />
                      )}
                    />
                  </div>
                  {errors._username && <span style={{ color: "#FF0000" }}>{errors._username.message}</span>}
                  <div className="input-container ic1 mt-20">
                    <Controller
                      control={control}
                      name="_password"
                      render={({ field: { name, onChange, value } }) => (
                        <div className="password-input-wrapper" style={{ position: "relative" }}>
                          <Text
                            name={name}
                            value={value}
                            type={showPassword ? "text" : "password"}
                            onChange={onChange}
                            label={t("Password")}
                          />
                          {/* Show/Hide password icon */}
                          <span
                            onClick={togglePasswordVisibility}
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "55%",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                              fontSize: "1.5em",
                            }}
                          >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </span>
                        </div>
                      )}
                    />
                  </div>
                  {errors._password && <span style={{ color: "#FF0000" }}>{errors._password.message}</span>}
                  {process.env.REACT_APP_NODE_MULTI_INSTANCE === "1" && (
                    <div style={{ paddingTop: 50 }}>
                      <Controller
                        control={control}
                        name={"instance"}
                        render={({ field: { name, onChange, value } }) => (
                          <Select
                            label={"Instance"}
                            tooltip={"Choose which instance to connect."}
                            value={instance}
                            options={[
                              { value: "https://dev.m-4.ch", label: "dev.m-4.ch" },
                              { value: "https://meb.caymland.app", label: "meb.caymland.app" },
                              { value: "https://htg.caymland.app", label: "htg.caymland.app" },
                            ]}
                            onChange={onChangeInstance}
                            required={true}
                            isClearable={false}
                          />
                        )}
                      />
                    </div>
                  )}
                  <div className="forgotPassword ">
                    <Link to="/reset">{t("Forgot Password?")}</Link>
                  </div>
                  <div className="form-group form-row mt-3 mb-0 d-grid">
                    <input type="submit" className="btn active-btn" value={isLoading ? t("Loading...") : t("Login")} />
                  </div>
                  {process.env.REACT_APP_NODE_MODE === "development" && (
                    <div className="form-group form-row mt-3 mb-0 d-grid text-center">
                      <span>
                        {t("Last update:")} {Moment(LastUpdate.last_update).fromNow()}
                      </span>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Login;
