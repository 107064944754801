import React from "react";
import { Modal } from "react-bootstrap";
import { t } from "i18next";
import Button from "../../../../reusableComponents/Buttons/Button";
import ThemeCards from "../addEmailContent/ThemeCards";

const CustomModal = ({ isOpenModal, setIsOpenModal, modifiedData, setModifiedData, themes }) => {
  return (
    <>
      <Modal show={isOpenModal} onHide={() => setIsOpenModal(false)} size="xl">
        <Modal.Header
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
          closeButton
        >
          <Modal.Title
            style={{
              padding: "4px",
              fontSize: "18px",
            }}
          >
            {t("caymland.core.filter.themes")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ padding: "20px" }}>
          <ThemeCards modifiedData={modifiedData} setModifiedData={setModifiedData} themes={themes} />
        </Modal.Body>

        <Modal.Footer>
          <Button
            buttons={[
              {
                title: t("caymland.core.close"),
                onClick: () => setIsOpenModal(false),
              },
            ]}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomModal;
