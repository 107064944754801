import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Statistics from "./TabsComponents/Statistics";
import History from "./TabsComponents/History";
import AuditLog from "./TabsComponents/AuditLog";
import Notes from "./TabsComponents/Notes";
import SeeTicket from "./TabsComponents/SeeTicket";
import Files from "./TabsComponents/Files";
import { useHistoryTabQuery } from "../../../../redux/api/contactsApi";
import Reservation from "./TabsComponents/Reservation";

const Tabs = ({ tabs, statistics, id, reload, setReload }) => {
  const hasStatisticsData = () => {
    return statistics && Object.keys(statistics).length > 0;
  };
  useEffect(() => {}, [statistics]);
  const [activeTab, setActiveTab] = useState(statistics ? "statistics" : "history");
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: "DESC",
  });
  const { data, isFetching, refetch } = useHistoryTabQuery({ id, query, includeEvents: [] });

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  useEffect(() => {
    if (hasStatisticsData()) {
      setActiveTab("statistics");
    } else {
      setActiveTab("history");
    }
  }, [statistics]);

  return (
    <div>
      <Nav tabs>
        {/* Render Statistics Tab first if data is available */}
        {hasStatisticsData() && (
          <NavItem>
            <NavLink className={activeTab === "statistics" ? "active-bg" : ""} onClick={() => toggle("statistics")}>
              Statistics
            </NavLink>
          </NavItem>
        )}

        {/* Render other tabs */}
        {tabs.map((tab, index) => (
          <NavItem key={index}>
            <NavLink className={activeTab === tab.alias ? "active-bg" : ""} onClick={() => toggle(tab.alias)}>
              {tab.name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      <TabContent activeTab={activeTab}>
        {/* Render Statistics Content */}
        {hasStatisticsData() && (
          <TabPane tabId="statistics">
            {activeTab === "statistics" && (
              <Statistics statistics={statistics} id={id} data={data} setQuery={setQuery} query={query} />
            )}
          </TabPane>
        )}
        {/* Render History Content */}
        <TabPane tabId="history">
          {activeTab === "history" && (
            <History
              setReload={setReload}
              reload={reload}
              id={id}
              data={data}
              isFetching={isFetching}
              refetch={refetch}
              setQuery={setQuery}
              query={query}
            />
          )}
        </TabPane>

        {/* Render AuditLog Content */}
        <TabPane tabId="audit_log">{activeTab === "audit_log" && <AuditLog id={id} />}</TabPane>
        {/* Render Notes Content */}
        <TabPane tabId="notes">{activeTab === "notes" && <Notes id={id} />}</TabPane>
        {/* Render See Tikcets*/}
        <TabPane tabId="showare">{activeTab === "showare" && <SeeTicket id={id} />}</TabPane>
        {/* Render Files*/}
        <TabPane tabId="reservations">{activeTab === "reservations" && <Reservation id={id}  />}</TabPane>
        <TabPane tabId="files">
          {activeTab === "files" && <Files id={id} tabData={tabs.filter((tab) => tab.alias === "files")} />}
        </TabPane>

        {/* Render other tab content */}
        {/* {tabs.map((tab, index) => (
          <TabPane tabId={tab.alias} key={index}>
            {activeTab === tab.alias ? <div>{`Content for ${tab.name}`}</div> : null}
          </TabPane>
        ))} */}
      </TabContent>
    </div>
  );
};

export default Tabs;
