import React, { useEffect, useState } from "react";
import Header from "../../../reusableComponents/Header/Header";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import {
  useGetContactAdvancedQuery,
  useGetContactByIdQuery,
  useHistoryTabQuery,
} from "../../../../redux/api/contactsApi";
import md5 from "blueimp-md5";
import DetailsSkeleton from "../../../reusableComponents/DetailsSkeleton";
import SidebarSkeleton from "../../../reusableComponents/SidebarSkeleton";
import DoNotContact from "../DoNotContact";
import Stage from "../TopBarComponents/Stage";
import Permission from "../TopBarComponents/Permission";
import Points from "../TopBarComponents/Points";
import Categories from "../TopBarComponents/Categories";
import Tags from "../TopBarComponents/Tags";
import FieldsForm from "../FieldsBar/FieldsForm";

import { useFieldArray, useForm } from "react-hook-form";
import { useGetContactFieldsQuery } from "../../../../redux/api/fieldsApi";
import Companies from "../ContactCompanies/Companies";
import Tabs from "../Tabs/Tabs";
import Campaigns from "../../../reusableComponents/Buttons/campaigns";
import Merge from "../../../reusableComponents/Buttons/merge";
import Preferences from "../../../reusableComponents/Buttons/preferences";
import Segments from "../../../reusableComponents/Buttons/segments";
import SendEmail from "../../../reusableComponents/Buttons/sendEmail";
import Delete from "../../../reusableComponents/Buttons/delete";
import { FaRegTimesCircle } from "react-icons/fa";
import HeaderSkeleton from "../../../reusableComponents/HeaderSkeleton";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import Timeline from "../../../reusableComponents/timeline";

const ViewContact = () => {
  const { t } = useTranslation();
  const [contactFields, setContactFields] = useState();
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [pointsValue, setPointsValue] = useState("");
  const [backgorundToast, setBackgroundToast] = useState("");
  const [reload, setReload] = useState(false);

  const location = useLocation();
  const id = location?.state?.id || location?.state || useParams().id;
  const navigate = useNavigate();

  const { data, isFetching, refetch, isLoading } = useGetContactAdvancedQuery(id);
  const { data: fields } = useGetContactFieldsQuery();
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: "DESC",
  });
  const { data: historytab } = useHistoryTabQuery({ id, query, includeEvents: [] });

  const eventScheduledData = historytab?.events?.filter((event) => event.event === "campaign.event.scheduled") || [];

  const { control, reset } = useForm();

  const { fields: categoriesFields, replace: replaceCategories } = useFieldArray({
    control,
    keyName: "formId",
    name: "categories",
  });

  const { fields: tagsFields, replace: replaceTags } = useFieldArray({
    control,
    name: "contact.tags",
    keyName: "formId",
  });
  const {
    fields: companiesFields,
    replace: replaceCompanies,
    update: updateCompanies,
  } = useFieldArray({
    control,
    name: "companies",
    keyName: "formId",
  });
  const { fields: tabs, replace: replaceTabs } = useFieldArray({
    control,
    name: "tabs",
    keyName: "formId",
  });
  const { fields: campaignsFields, update: updateCampaigns } = useFieldArray({
    control,
    name: "campaigns",
    keyName: "formId",
  });
  const { fields: segmentFields, update: updateSegments } = useFieldArray({
    control,
    name: "lists",
    keyName: "formId",
  });
  const { fields: pointsFields, update: updatePoints } = useFieldArray({
    control,
    name: "points",
    keyName: "formId",
  });

  useEffect(() => {
    reset(data);
  }, [data]);
  //TOP CARD
  const firstName = data?.contact?.fields?.all?.firstname ? data.contact?.fields?.all?.firstname : "";
  const lastName = data?.contact?.fields?.all?.lastname ? data.contact?.fields?.all?.lastname : "";
  function getGravatarURL(email, size = 80) {
    const defaultImageURL = "https://cdn-icons-png.flaticon.com/512/149/149071.png";

    if (!email) {
      return defaultImageURL;
    }
    const hash = md5(email.toLowerCase().trim());
    return `https://www.gravatar.com/avatar/${hash}?s=${size}&d=${encodeURIComponent(defaultImageURL)}`;
  }
  let imageURL = getGravatarURL(data?.contact?.fields?.all?.email);

  //TABS & FIELDS
  useEffect(() => {
    if (fields) {
      setContactFields(fields?.data);
    }
  }, [fields]);

  const navBack = () => {
    navigate("/contacts");
  };

  const extraButtonsComponent = [
    <Segments
      noText={true}
      firstName={firstName}
      lastName={lastName}
      navigate={navBack}
      segments={segmentFields}
      updateSegments={updateSegments}
      id={id}
    />,
    <Campaigns
      campaignsFields={campaignsFields}
      noText={true}
      firstName={firstName}
      lastName={lastName}
      navigate={navBack}
      updateCampaigns={updateCampaigns}
    />,
    <Merge noText={true} navigate={navBack} name={firstName} id={id} firstName={firstName} lastName={lastName} />,
    <Preferences
      noText={true}
      segments={segmentFields}
      contactCategories={categoriesFields}
      allSegments={segmentFields}
      allCategories={categoriesFields}
      setShowAlert={setShow}
      data={data?.preferenceCenter}
      setMessage={setMessage}
      setBackgroundToast={setBackgroundToast}
      id={id}
      categoriesFields={categoriesFields}
      replaceCategories={replaceCategories}
    />,

    <SendEmail
      noText={true}
      email={data?.contact?.fields?.all?.email}
      setReload={setReload}
      navigate={navBack}
      id={id}
    />,
    <Delete noText={true} id={id} firstName={firstName} component="contact" navigate={navBack} />,
  ];
  const buttons = [
    {
      name: "",
      title: "",
      icon: (
        <Tooltip title={t("caymland.core.form.close")} arrow>
          {" "}
          <FaRegTimesCircle size={15} />
        </Tooltip>
      ),
      onClick: () => navBack(),
    },
  ];

  return (
    <>
      {isLoading ? (
        <HeaderSkeleton isView={true} isContactView={true} />
      ) : (
        <Header
          title={t("caymland.lead.list.view_contacts")}
          alertMessage={message}
          showAlert={show}
          buttons={buttons}
          extraButtonsComponent={extraButtonsComponent}
          background={backgorundToast}
        />
      )}
      <div className="flex flex-col gap-20 " style={{ minHeight: "850px", height: "100%" }}>
        {isLoading ? (
          <DetailsSkeleton company />
        ) : (
          <div className="card-top w-full ">
            <div className="w-full flex items-center justify-between">
              <div className="avatar flex items-center gap-5px view-contact-heading-bar-items">
                <img className="pro" alt="" src={imageURL} data-intro="This is Profile image" width="100" />
                <div className="name-position">
                  <div className="d-flex items-center gap-10">
                    <h2 style={{ marginBottom: "0" }}>{firstName + " " + lastName}</h2>
                    <DoNotContact doNotContact={data?.contact?.doNotContact?.length} data={data} />
                  </div>
                  <br />
                  <p style={{ marginBottom: "0" }}>
                    {data?.contact?.fields.all?.position ? data.contact?.fields.all?.position : ""}
                  </p>
                </div>
              </div>
              <div className="d-flex items-center justify-between ">
                {/* <Permission data={data} /> */}
                <Points data={data} pointsValue={pointsValue} />
                <Stage
                  setBackgroundToast={setBackgroundToast}
                  control={control}
                  data={data}
                  setShow={setShow}
                  setMessage={setMessage}
                />
              </div>
            </div>
            <div
              className="w-full flex items-center justify-between"
              style={{ marginTop: "10px", padding: "8px", borderTop: "1px solid gainsboro", minHeight: "70px" }}
            >
              <Categories
                categoriesFields={categoriesFields}
                replaceCategories={replaceCategories}
                setMessage={setMessage}
                setShow={setShow}
                setBackgroundToast={setBackgroundToast}
              />
              <Tags
                setBackgroundToast={setBackgroundToast}
                tagsFields={tagsFields}
                replaceTags={replaceTags}
                setMessage={setMessage}
                setShow={setShow}
              />
            </div>
          </div>
        )}

        <div className="w-full flex items-start justify-between gap-20 ">
          {isLoading ? (
            <SidebarSkeleton applyPadding />
          ) : (
            <div className="d-flex flex-col gap-20 " style={{ width: "30%" }}>
              <div className="card-top" style={{ padding: 0 }}>
                <FieldsForm
                  setPointsValue={setPointsValue}
                  contactFields={contactFields}
                  data={data?.contact}
                  setMessage={setMessage}
                  setShow={setShow}
                  setBackgroundToast={setBackgroundToast}
                />
              </div>
              {isFetching ? (
                <SidebarSkeleton applyPadding />
              ) : (
                <>
                  <Companies
                    companiesFields={companiesFields}
                    setMessage={setMessage}
                    setShow={setShow}
                    updateCompanies={updateCompanies}
                    replaceCompanies={replaceCompanies}
                    isFetching={isFetching}
                    setBackgroundToast={setBackgroundToast}
                  />

                  <Timeline events={eventScheduledData} />
                </>
              )}
            </div>
          )}
          {isFetching ? (
            <SidebarSkeleton applyPadding />
          ) : (
            <div
              className="card-top"
              style={{
                padding: 0,
                width: "70%",
                minHeight: "890px",
                maxHeight: "calc(100% - 20px)",
                marginBottom: "10px",
              }}
            >
              <Tabs tabs={tabs} reload={reload} setReload={setReload} statistics={pointsFields} id={id} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewContact;
