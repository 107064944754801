import React, { useRef, useState, useEffect } from "react";
import PagesEditor from "react-email-editor";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useGetBuilderConfigQuery } from "../../../../../redux/api/usersApi";

const Builder = ({
  editorData: { projectId, mergeTags },
  setModifiedData,
  modifiedData,
  apply,
  setApplyAndCloseTemplateRef,
}) => {
  const { t } = useTranslation();
  const pagesEditorRef = useRef(null);
  const [shouldApply, setShouldApply] = useState(false);
  const [applyBuilder, setApplyBuilder] = useState("");
  const { data } = useGetBuilderConfigQuery("web");

  const applyAndCloseTemplate = (params) => {
    pagesEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;

      // Ensure line breaks are properly encoded
      const encodedHtml = Buffer.from(html).toString("base64");
      const encodedJson = Buffer.from(JSON.stringify(design, undefined)).toString("base64");

      setModifiedData((prev) => ({
        ...prev,
        customHtml: encodedHtml,
        customJson: encodedJson,
      }));

      // Directly update the editor with the latest design
      pagesEditorRef.current.editor.loadDesign(design);

      if (params === "applyBuilder") {
        setApplyBuilder("applyBuilder");
      }

      if (params === "savebuilder") {
        setApplyBuilder("savebuilder");
      }
      setShouldApply(true);
    });
  };

  useEffect(() => {
    // Pass the applyAndCloseTemplate function to the parent component
    if (setApplyAndCloseTemplateRef) {
      setApplyAndCloseTemplateRef(() => applyAndCloseTemplate);
    }
  }, [setApplyAndCloseTemplateRef]); // Only run when setApplyAndCloseTemplateRef changes

  useEffect(() => {
    if (shouldApply) {
      apply(applyBuilder);
      setShouldApply(false);
    }
  }, [shouldApply]);

  const onReady = () => {
    if (modifiedData?.customJson) {
      try {
        const design = JSON.parse(modifiedData?.customJson);
        pagesEditorRef?.current?.editor?.loadDesign(design);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      console.log("No custom JSON data to load.");
    }
  };

  return (
    <>
      <PagesEditor ref={pagesEditorRef} onReady={onReady} minHeight="100%" projectId={projectId} options={data} />
    </>
  );
};

export default Builder;
